import { createSlice } from '@reduxjs/toolkit'

export const languageSlice = createSlice({
    name: 'language',
    initialState: {
        value: 'cs'
    },
    reducers: {
        setCzech: state => { state.value = 'cs' },
        setGerman: state => { state.value = 'de' }
    }
})

export const { setCzech, setGerman } = languageSlice.actions
export const languageReducer = languageSlice.reducer
