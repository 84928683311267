import '../styles/tailwind.css'
import '../styles/global.scss';
import '../styles/global_cs.scss';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { Provider } from 'react-redux';
import store from '../store';
import Layout from '../components/Layout';
import { ReCaptchaProvider } from "next-recaptcha-v3";

function MyApp({ Component, pageProps }) {

    return (
        <Provider store={store}>

                <Layout>
                    <Component {...pageProps} />
                </Layout>

        </Provider>
    );
}

export default appWithTranslation(MyApp);