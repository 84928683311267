import Script from 'next/script';
import Footer from './Footer';
import Header from './Header';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export default function Layout({ children }) {    
    const { t } = useTranslation('common'); 
    return (
        <div className="milka">
            <Header />
            {children}
            <Script strategy='beforeInteractive' id="google-analytics" key="google-analytics">{` (function (w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', '${ t('cookies.tag')}'); console.log('gtm'); `}</Script>
            <Footer />
        </div>
    );
}


export async function getServerSideProps({ req, res }) {
  const host = req.headers.host;
  const hostnameParts = host.split('.');
  const tld = hostnameParts.pop();
  const defaultLang = 'cs';
  let fallbackLang;

  const tldToLangMap = {
    hu: 'hu',
    cz: 'cs',
    sk: 'sk',
  };

  if( host ) {
    fallbackLang = tldToLangMap[tld] || defaultLang;
  }

  
  const lang = fallbackLang;
  const fullPath = host;
  return {
    props: {
      lang,
      fullPath,
      ...(await serverSideTranslations(lang, ['common'])),
    },
  };
}
