import { useTranslation } from "next-i18next";

export default function Footer() {
    const { t } = useTranslation('common');
    return (
        <footer className="footer"> 
            <div className="footer-socials">
                <a className="footer-socials-instagram" href={ t('footer.links.socials.ig') } target="_blank"></a>
                <a className="footer-socials-facebook" href={ t('footer.links.socials.fb') } target="_blank"></a>
                <a className="footer-socials-youtube" href={ t('footer.links.socials.yt') } target="_blank"></a>
                {/* <a className="footer-socials-pinterest" href="https://www.pinterest.de/milka_dach/" target="_blank"></a>
                <a className="footer-socials-tiktok" href="https://www.tiktok.com/@milka_de_at" target="_blank"></a> */}
            </div>
            <div className="footer-links">
                <div className="footer-links-group">
                    <a href={ t('footer.links.terms_link') } target="_blank">{ t('footer.links.terms') }</a>
                    <a href={ t('footer.links.data_link') } target="_blank">{ t('footer.links.data') }</a>
                    <a href={ t('footer.links.contact_link') } target="_blank">{ t('footer.links.contact') }</a>
                    {/* <a href="/pdf/Allgemeine_geschaftsbedingungen.pdf" target="_blank">Allgemeine Geschäftsbedingungen</a> */}
                    <a href="/cookies" target="_blank">{ t('footer.links.cookies') }</a>
                </div>
                <div className="footer-links-group">
                    {/* <a href="#" target="_blank">Mondelez International</a> */}
                    {/* <a href="#" target="_blank">Cookie Richtlinie</a> */}
                    {/* <a href="#" target="_blank">FAQ</a> */}
                </div>
            </div>
            <div className="footer-divider bg-fresh"></div>
            <div className="footer-brand">
                <div className="footer-brand-logo">
                    <a href={ t("footer.links.logo") } target="_blank"><img src="/images/mondelez.png" alt="Mondelez"/></a>
                    <a className="hidden purple" href="https://milka.de/" target="_blank"><img src="/images/mondelez_purple.png" alt="Mondelez"/></a>
                </div>
                <span className="footer-brand-text uppercase">{ t('footer.copy') }</span>
            </div>
        </footer>
    );
}