import { configureStore } from '@reduxjs/toolkit';
import walkthroughReducer from './slices/walkthroughSlice';
import milkulasReducer from './slices/milkulasSlice';
import photoEditorReducer from './slices/photoEditorSlice';
import videoEditorReducer from './slices/videoEditorSlice';
import { collectiblesReducer } from '../external/yord-santa/src/modules/slices/collectiblesSlice';
import { languageReducer } from '../external/yord-santa/src/modules/slices/languageSlice';
import { santaFigureReducer } from '../external/yord-santa/src/modules/slices/santaFigureSlice';
import { cameraReducer } from '../external/yord-santa/src/modules/slices/cameraSlice';
import { userReducer } from '../external/yord-santa/src/modules/slices/userSlice';
import dataMessageReducer from './slices/dataMessageSlice';



const store = configureStore({
    reducer: {
        walkthrough: walkthroughReducer,
        milkulas: milkulasReducer,
        photoEditor: photoEditorReducer,
        videoEditor: videoEditorReducer,
        collectibles: collectiblesReducer,
        language: languageReducer,
        santaFigure: santaFigureReducer,
        camera: cameraReducer,
        user: userReducer,
        dataMessage: dataMessageReducer
    },
});

export default store;