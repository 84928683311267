import { createSlice } from '@reduxjs/toolkit';

const dataMessageSlice = createSlice({
    name: 'dataMessage',
    initialState: {
        sound: null,
        phonems: null,
        hash: null,
        isWinner: false,
        isWinnerId: null,
        isIOSReady: true,
        isSupportedBrowser: true,
        lang: 'cs',
        animationStart: false,
        showOverlay: false,
        audioSuspended: false,
        fullPath: '',
        playAgainSafari: false,
        startedOnSafariIOS: false,
    },
    reducers: {
        setSound(state, action) {
            state.sound = action.payload;
        },
        setPhonems(state, action) {
            state.phonems = action.payload;
        },
        setHash(state, action) {
            state.hash = action.payload;
        },
        setIsWinner(state, action) {
            state.isWinner = action.payload
        },
        setIsWinnerId(state, action) {
            state.isWinnerId = action.payload
        },
        setIsIOSReady(state, action) {
            state.isIOSReady = action.payload
        },
        setLang(state, action) {
            state.lang = action.payload
        },
        setAnimationStart(state, action) {
            state.animationStart = action.payload
        },
        setShowOverlay(state, action) {
            state.showOverlay = action.payload
        },
        setAudioSuspended(state, action) {
            state.audioSuspended = action.payload
        },
        setIsSupportedBrowser(state, action) {
            state.isSupportedBrowser = action.payload
        },
        setFullPath(state, action) {
            state.fullPath = action.payload
        },
        setPlayAgainSafari(state, action) {
            state.playAgainSafari = action.payload
        },
        setStartedOnSafariIOS(state, action) {
            state.startedOnSafariIOS = action.payload
        },
    },
});

export const { 
    setSound, 
    setPhonems, 
    setHash, 
    setIsWinner, 
    setIsWinnerId, 
    setIsIOSReady, 
    setLang, 
    setAnimationStart, 
    setShowOverlay,
    setAudioSuspended,
    setIsSupportedBrowser,
    setFullPath,
    setStartedOnSafariIOS,
    setPlayAgainSafari
 } = dataMessageSlice.actions;
export default dataMessageSlice.reducer;