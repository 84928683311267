// store/slices/photoEditorSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    photoDataUrl: '',
    stickers: [],
    dimensions: { width: 640, height: 1128 },
    photoTaken: false,
    maskDataUrl: '', 
};

const photoEditorSlice = createSlice({
    name: 'photoEditor',
    initialState,
    reducers: {
        setPhotoDataUrl(state, action) {
            state.photoDataUrl = action.payload;
        },
        setStickers(state, action) {
            state.stickers = action.payload;
        },
        setDimensions(state, action) {
            state.dimensions = { width: 640, height: 1128 };
        },
        resetPhotoEditor(state) {
            state.photoDataUrl = '';
            state.stickers = [];
            state.dimensions = { width: 640, height: 1128 };
            state.photoTaken = false;
            state.maskDataUrl = '';
        },
        setPhotoTaken(state, action) {
            state.photoTaken = action.payload;
        },
        setMaskDataUrl(state, action) {
            state.maskDataUrl = action.payload;
        },
    },
});

export const {
    setPhotoDataUrl,
    setStickers,
    setDimensions,
    resetPhotoEditor,
    setPhotoTaken,
    setMaskDataUrl,
} = photoEditorSlice.actions;

export default photoEditorSlice.reducer;
