import { useSelector } from 'react-redux';
import Link from 'next/link';

export default function Header() {
    const isBlurred = useSelector((state) => state.walkthrough.isBlurred);
    return (
        <header className={`header max-w-[1920px] mx-auto ${ isBlurred && 'logo-left' }`}> 
            <Link className='logo' href='/'><img src='/images/milka.png' alt="Milka"/></Link>
        </header>
    );
}