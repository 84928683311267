import { createSlice } from '@reduxjs/toolkit';

const milkulasSlice = createSlice({
    name: 'milkulas',
    initialState: {
        name: '',
    },
    reducers: {
        updateForm(state, action) {
            state.name = action.payload.name;
        },
    },
});

export const { updateForm } = milkulasSlice.actions;
export default milkulasSlice.reducer;