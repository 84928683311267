export const CollectibleItemsCount = 5
export const CollectiblesToWin = 3

export const CameraModes = {
    Rotating: 0,
    Orbit: 1
}

export const CameraTargets = {
    None: 'none',
    Santa: 'santa',
    Side: 'side'
}

export const CameraTargetSettings = {
    'santa': {
        horizontal: 143,
        vertical: .000185,
        fov: 30
    },
    'side': {
        horizontal: 0,
        vertical: 0,
        fov: 55
    },
}

export const CameraInitialAngles = {
    de: {
        horizontal: 270,
        vertical: -.0001
    },
    cs: {
        horizontal: 143,
        vertical: .0004
    },
    sk: {
        horizontal: 143,
        vertical: .0004
    },
    hu: {
        horizontal: 143,
        vertical: .0004
    }
}

export const Lerp = function(a, b, t) {
    return a + (b - a) * t
}
