import { createSlice } from '@reduxjs/toolkit'

export const collectiblesSlice = createSlice({
    name: 'collectibles',
    initialState: {
        collectedCount: 0,
        hasWon: false
    },
    reducers: {
        incrementCollectedItems: state => { state.collectedCount += 1 },
        setHasWon: state => { state.hasWon = true }
    }
})

export const { incrementCollectedItems, setHasWon } = collectiblesSlice.actions
export const collectiblesReducer = collectiblesSlice.reducer
