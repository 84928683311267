import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        isGiver: true,
        isWinner: true
    },
    reducers: {
        setIsGiver: state => { state.isGiver = true },
        setIsReceiver: state => { state.isGiver = false },
        setIsWinner: state => { state.isWinner = true },
        setIsLoser: state => { state.isWinner = false }
    }
})

export const { setIsGiver, setIsReceiver, setIsWinner, setIsLoser } = userSlice.actions
export const userReducer = userSlice.reducer
